import React from "react";
import { string, bool } from "prop-types";
import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import AppetizeImage from "../../../components/ImageQuerys/AppetizeImages";
import useStartMotion from "../../../hooks/use-start-motion";

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
  },
};

const LargeImageFullWidth = ({ className, imageName, animations }) => {
  const { ref, controls } = useStartMotion();

  return (
    <section
      data-cy="large-image-full-width"
      className={`relative
        max-w-1440 w-full
        px-4 lg:px-20 xl:px-60
        ${className}
      `}
      ref={ref}
    >
      <motion.div
        animate={controls}
        initial={isMobileOnly || !animations ? "visible" : "hidden"}
        variants={fadeInVariants}
        transition={{ delay: 0.3, duration: 0.8 }}
      >
        <AppetizeImage imageName={imageName} />
      </motion.div>
    </section>
  );
};

LargeImageFullWidth.propTypes = {
  className: string,
  imageName: string.isRequired,
  animations: bool,
};

LargeImageFullWidth.defaultProps = {
  className: "",
  animations: true,
};

export default LargeImageFullWidth;
